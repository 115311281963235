#breadcrumb_top {
    border-radius: 0 !important; /* stylelint-disable-line declaration-no-important */
}

header.main-header {
    margin-bottom: 0 !important; /* stylelint-disable-line declaration-no-important */
}

ngb-modal-window.modal {
    display: flex !important; /* stylelint-disable-line declaration-no-important */
    justify-content: center;
    align-items: center;
}

ngb-modal-window is-interpolate span p {
    line-height: unset;
    display: inline;
}

#spinnerWaitMessage {
    margin: 0 0 12.5px;
}

.nav-tabs {
    padding-left: 0;
}

.tile-label {
    padding-top: 3px;
}

.documents-tile {
    height: 255px !important; /* stylelint-disable-line declaration-no-important */
}

.share-document.bolt-remove-underline#renewalIdCardsshareDocument_renewalIdCards,
.share-document.bolt-remove-underline#insuranceIdCardsshareDocument_insuranceIdCards {
    display: grid;
}

ngb-modal-window.fade {
    transition: opacity 0.15s linear !important; /* stylelint-disable-line declaration-no-important */
    opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
    ngb-modal-window.fade {
        transition: none !important; /* stylelint-disable-line declaration-no-important */
        opacity: 1;
    }
}

ngb-modal-window.fade:not(.show) {
    opacity: 0;
}

ngb-modal-window .component-host-scrollable {
    overflow: initial !important; /* stylelint-disable-line declaration-no-important */
}

.ways-to-pay-modal {
    .modal {
        --bs-modal-zindex: 1055;
        --bs-modal-width: 500px;
        --bs-modal-padding: 1rem;
        --bs-modal-margin: 0.5rem;
        --bs-modal-color: ;
        --bs-modal-bg: #fff;
        --bs-modal-border-color: var(--bs-border-color-translucent);
        --bs-modal-border-width: 1px;
        --bs-modal-border-radius: 0.5rem;
        /* stylelint-disable-next-line alpha-value-notation */
        --bs-modal-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); /* stylelint-disable-line color-function-notation */
        --bs-modal-inner-border-radius: calc(0.5rem - 1px);
        --bs-modal-header-padding-x: 1rem;
        --bs-modal-header-padding-y: 1rem;
        --bs-modal-header-padding: 1rem 1rem;
        --bs-modal-header-border-color: var(--bs-border-color);
        --bs-modal-header-border-width: 1px;
        --bs-modal-title-line-height: 1.5;
        --bs-modal-footer-gap: 0.5rem;
        --bs-modal-footer-bg: ;
        --bs-modal-footer-border-color: var(--bs-border-color);
        --bs-modal-footer-border-width: 1px;

        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--bs-modal-zindex);
        display: none;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto; /* stylelint-disable-line declaration-block-no-redundant-longhand-properties */
        outline: 0;
    }

    .modal-dialog {
        position: relative;
        width: auto;
        margin: var(--bs-modal-margin);
        pointer-events: none;
        padding: 0;
        overflow-y: initial !important; /* stylelint-disable-line declaration-no-important */
    }

    .modal.fade .modal-dialog {
        transition: transform 0.3s ease-out;
        transform: translate(0, -50px);
    }

    @media (prefers-reduced-motion: reduce) {
        .modal.fade .modal-dialog {
            transition: none;
        }
    }

    .modal.show .modal-dialog {
        transform: none;
    }

    .modal.modal-static .modal-dialog {
        transform: scale(1.02);
    }

    .modal-dialog-scrollable {
        height: calc(100% - var(--bs-modal-margin) * 2);
    }

    .modal-dialog-scrollable .modal-content {
        max-height: 100%;
        overflow-y: auto;
    }

    .modal-dialog-scrollable .modal-body {
        overflow-y: auto;
    }

    .modal-dialog-centered {
        display: flex;
        align-items: center;
        min-height: calc(100% - var(--bs-modal-margin) * 2);
    }

    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        color: var(--bs-modal-color);
        pointer-events: auto;
        background-color: var(--bs-modal-bg);
        background-clip: padding-box;
        border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
        border-radius: var(--bs-modal-border-radius);
        outline: 0;
    }

    .modal-backdrop {
        --bs-backdrop-zindex: 1050;
        --bs-backdrop-bg: #000;
        --bs-backdrop-opacity: 0.5;

        position: fixed;
        top: 0;
        left: 0;
        z-index: var(--bs-backdrop-zindex);
        width: 100vw;
        height: 100vh;
        background-color: var(--bs-backdrop-bg);
    }

    .modal-backdrop.fade {
        opacity: 0;
    }

    .modal-backdrop.show {
        opacity: var(--bs-backdrop-opacity);
    }

    .modal-header {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: space-between;
        padding: var(--bs-modal-header-padding);
        border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
        border-top-left-radius: var(--bs-modal-inner-border-radius);
        border-top-right-radius: var(--bs-modal-inner-border-radius);
    }

    .modal-header .btn-close {
        padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
        margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x))
            calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
    }

    .modal-title {
        margin-bottom: 0;
        line-height: var(--bs-modal-title-line-height);
    }

    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: var(--bs-modal-padding);
    }

    .modal-footer {
        display: flex;
        flex-shrink: 0;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
        background-color: var(--bs-modal-footer-bg);
        border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
        border-bottom-right-radius: var(--bs-modal-inner-border-radius);
        border-bottom-left-radius: var(--bs-modal-inner-border-radius);
    }

    .modal-footer > * {
        margin: calc(var(--bs-modal-footer-gap) * 0.5);
    }

    @media (min-width: 576px) {
        .modal {
            --bs-modal-margin: 1.75rem;
            /* stylelint-disable-next-line alpha-value-notation, color-function-notation */
            --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        }

        .modal-dialog {
            max-width: var(--bs-modal-width);
            margin-right: auto;
            margin-left: auto;
        }

        .modal-sm {
            --bs-modal-width: 300px;
        }
    }

    @media (min-width: 992px) {
        .modal-lg,
        .modal-xl {
            --bs-modal-width: 800px;
        }
    }

    @media (min-width: 1200px) {
        .modal-xl {
            --bs-modal-width: 1140px;
        }
    }

    .modal-fullscreen {
        width: 100vw;
        max-width: none;
        height: 100%;
        margin: 0;
    }

    .modal-fullscreen .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0;
    }

    .modal-fullscreen .modal-footer,
    .modal-fullscreen .modal-header {
        border-radius: 0;
    }

    .modal-fullscreen .modal-body {
        overflow-y: auto;
    }

    @media (max-width: 575.98px) {
        .modal-fullscreen-sm-down {
            width: 100vw;
            max-width: none;
            height: 100%;
            margin: 0;
        }

        .modal-fullscreen-sm-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-sm-down .modal-footer,
        .modal-fullscreen-sm-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-sm-down .modal-body {
            overflow-y: auto;
        }
    }

    @media (max-width: 767.98px) {
        .modal-fullscreen-md-down {
            width: 100vw;
            max-width: none;
            height: 100%;
            margin: 0;
        }

        .modal-fullscreen-md-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-md-down .modal-footer,
        .modal-fullscreen-md-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-md-down .modal-body {
            overflow-y: auto;
        }
    }

    @media (max-width: 991.98px) {
        .modal-fullscreen-lg-down {
            width: 100vw;
            max-width: none;
            height: 100%;
            margin: 0;
        }

        .modal-fullscreen-lg-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-lg-down .modal-footer,
        .modal-fullscreen-lg-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-lg-down .modal-body {
            overflow-y: auto;
        }
    }

    @media (max-width: 1199.98px) {
        .modal-fullscreen-xl-down {
            width: 100vw;
            max-width: none;
            height: 100%;
            margin: 0;
        }

        .modal-fullscreen-xl-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-xl-down .modal-footer,
        .modal-fullscreen-xl-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-xl-down .modal-body {
            overflow-y: auto;
        }
    }

    @media (max-width: 1399.98px) {
        .modal-fullscreen-xxl-down {
            width: 100vw;
            max-width: none;
            height: 100%;
            margin: 0;
        }

        .modal-fullscreen-xxl-down .modal-content {
            height: 100%;
            border: 0;
            border-radius: 0;
        }

        .modal-fullscreen-xxl-down .modal-footer,
        .modal-fullscreen-xxl-down .modal-header {
            border-radius: 0;
        }

        .modal-fullscreen-xxl-down .modal-body {
            overflow-y: auto;
        }
    }
}

.ways-to-pay-iframe {
    min-height: 272px; // Anything less than this will display a scroll bar while loading the widget
    width: 100%;
    min-width: 350px;
}
